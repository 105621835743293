import {
    Button,
    FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'
import SelectField from './../SelectField';
import { filter } from '../../utils/json';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={ filter(props.profile, ['user_metadata.language']) }
            onSubmit={ (values) => props.handleSave(values) }
            validationSchema={ Yup.object().shape({
                user_metadata: Yup.object().shape({
                    language: Yup.string()
                        .required(t('validation.required', { field: t('field.language') })),
                })
            }) }
        >
            { ({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={ handleSubmit }>
                    <FormControl fullWidth className={ classes.margin }>
                        <SelectField
                            label={ t('personalization.title.language_default') }
                            property="user_metadata.language"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.language', '') }
                            error={ getIn(touched, 'user_metadata.language') && getIn(errors, 'user_metadata.language') }
                            options={ [
                                { value: 'en', label: 'English'},
                                { value: 'nl', label: 'Nederlands'}
                            ] }
                        />
                    </FormControl>

                    <FormControl className={ classes.margin }>
                        <Button type="submit"  variant="contained" color="primary" disableElevation>
                            { t('action.save') }
                        </Button>
                    </FormControl>
                </Form>
            ) }
        </Formik>
    );
}
