import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileForm from './Form/ProfileForm';
import WorkForm from './Form/WorkForm';
import ContactForm from './Form/ContactForm';
import AddressForm from './Form/AddressForm';
import PasswordForm from './Form/PasswordForm';

export default (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h5" gutterBottom>
                            { t('personal_information.title.page') }
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            { t('personal_information.text.introduction') }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container justify="center" spacing={ 2 }>
                <Grid item xs={ 8 }>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                { t('personal_information.title.profile') }
                            </Typography>

                            <ProfileForm handleSave={ props.handleSave } handlePictureSave={ props.handlePictureSave } profile={ props.profile } />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 8 }>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                { t('personal_information.title.password') }
                            </Typography>

                            <PasswordForm handlePasswordChangeRequest={ props.handlePasswordChangeRequest } profile={ props.profile } />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 8 }>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                { t('personal_information.title.work') }
                            </Typography>

                            <WorkForm handleSave={ props.handleSave } profile={ props.profile } />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 8 }>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                { t('personal_information.title.contact') }
                            </Typography>

                            <ContactForm handleSave={ props.handleSave } profile={ props.profile } />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 8 }>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                { t('personal_information.title.address') }
                            </Typography>

                            <AddressForm countries={ props.countries } handleSave={ props.handleSave } profile={ props.profile } />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
