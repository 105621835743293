import {
    Box, Button, FormControl,
    Grid,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default () => {
    const { loginWithRedirect } = useAuth0();
    const classes = useStyles();
    const { t } = useTranslation();

    const fn = async () => {
        await loginWithRedirect({
            appState: { targetUrl: '/' }
        });
    };

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h5" gutterBottom>
                            { t('session_expired.title') }
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            { t('session_expired.text') }
                        </Typography>

                        <FormControl className={ classes.margin }>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={ fn }>
                                { t('action.login') }
                            </Button>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
