import { createAction } from 'redux-api-middleware';

export const COUNTRY_GET_COLLECTION_REQUEST = '@@events/COUNTRY_GET_COLLECTION_REQUEST';
export const COUNTRY_GET_COLLECTION_SUCCESS = '@@events/COUNTRY_GET_COLLECTION_SUCCESS';
export const COUNTRY_GET_COLLECTION_FAILURE = '@@events/COUNTRY_GET_COLLECTION_FAILURE';

export const getCountries = () => createAction({
    endpoint: 'https://unpkg.com/i18n-iso-countries@4.3.1/langs/nl.json',
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    types: [
        COUNTRY_GET_COLLECTION_REQUEST,
        COUNTRY_GET_COLLECTION_SUCCESS,
        COUNTRY_GET_COLLECTION_FAILURE
    ]
});
