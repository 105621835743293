import { Button, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, getIn } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TextField from '../TextField';
import { filter } from '../../utils/json';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={ filter(props.profile, ['user_metadata.organization', 'user_metadata.job_title']) }
            onSubmit={ (values) => props.handleSave(values) }
            validationSchema={Yup.object().shape({
                user_metadata: Yup.object().shape({
                    organization: Yup.string()
                        .required(t('validation.required', {field: t('field.organization')})),
                    job_title: Yup.string()
                        .required(t('validation.required', {field: t('field.job_title')})),
                })
            })}
        >
            { ({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={ handleSubmit }>
                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.organization') }
                            property="user_metadata.organization"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.organization', '') }
                            error={ getIn(touched, 'user_metadata.organization') && getIn(errors, 'user_metadata.organization') }
                        />
                    </FormControl>

                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.job_title') }
                            property="user_metadata.job_title"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.job_title', '') }
                            error={ getIn(touched, 'user_metadata.job_title') && getIn(errors, 'user_metadata.job_title') }
                        />
                    </FormControl>

                    <FormControl className={ classes.margin }>
                        <Button type="submit" variant="contained" color="primary" disableElevation>
                            { t('action.save') }
                        </Button>
                    </FormControl>
                </Form>
            ) }
        </Formik>
    );
}