import { isArray, isObject }  from 'lodash-es';

function getPathComponents(paths) {
    const pathComponents = [];

    paths.forEach(filter => {
        const parts = filter.split('.');

        parts.forEach((part, index) => {
            if (pathComponents[index] && -1 === pathComponents[index].indexOf(part)) {
                pathComponents[index].push(part);
            }
            else {
                pathComponents[index] = [part];
            }
        });
    });

    return pathComponents;
}

function filterByArrayKeys(value, pathComponents) {
    return value.filter((value, key) => {
        return false;
    });
}

function filterByObjectKeys(value, pathComponents) {
    const result = {};

    Object.keys(value).forEach(key => {
        if (pathComponents[0] && pathComponents[0].includes(key)) {
            result[key] = filterValue(value[key], pathComponents.slice(1));
        }
    });

    return result;
}

function filterValue(value, pathComponents) {
    if (0 < pathComponents.length) {
        if (isObject(value)) {
            return filterByObjectKeys(value, pathComponents);
        }
        else if (isArray(value)) {
            return filterByArrayKeys(value, pathComponents);
        }
    }

    return value;
}

export function filter(value, paths) {
    const pathComponents = getPathComponents(paths);

    return filterValue(value, pathComponents);
}