import React from 'react';
import { connect } from 'react-redux'
import { getProfile } from '../redux/actions/user';
import Home from '../components/Home';
import ProgressOverlay from '../components/ProgressOverlay';

class HomeContainer extends React.Component {
    componentDidMount() {
        if (null === this.props.profile) {
            this.props.getProfile(this.props.auth0User.sub);
        }
    }

    render() {
        if (this.props.loading || !this.props.profile) {
            return <ProgressOverlay loading={ true } />;
        }
        else {
            return <Home profile={ this.props.profile } />
        }
    }
}

const mapStateToProps = ({ user, auth0 }, ownProps) => {
    return {
        loading: user.loading,
        profile: user.profile,
        auth0User: auth0.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getProfile: (id) => dispatch(getProfile(id))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContainer);

