import { Button, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const [requested, setRequested] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const allowPasswordChange = props.profile.identities.filter(identity => identity.provider === 'auth0').length >= 1;

    if (allowPasswordChange) {
        return (
            <FormControl className={ classes.margin }>
                { requested ? (
                    <p>{ t('personal_information.text.password_change_requested') }</p>
                ) : (
                    <Button type="submit" variant="contained" color="primary" disableElevation onClick={ () => {
                        setRequested(true);
                        props.handlePasswordChangeRequest();
                    } }>
                        { t('personal_information.action.password_change') }
                    </Button>
                ) }
            </FormControl>
        );
    }
    else {
        return (
            <p>{ t('personal_information.text.password_change_not_available') }</p>
        );
    }
}
