import React from 'react';
import { connect } from 'react-redux'
import { getProfile, patchProfile } from '../redux/actions/user';
import ProgressOverlay from '../components/ProgressOverlay';
import Personalization from '../components/Personalization';

class PersonalizationContainer extends React.Component {
    componentDidMount() {
        if (null === this.props.profile) {
            this.props.getProfile(this.props.auth0User.sub);
        }
    }

    handleSave = (values) => {
        const allowed = ['given_name', 'family_name', 'user_metadata'];

        const filtered = Object.keys(values)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});

        this.props.patchProfile(this.props.auth0User.sub, filtered);
    };

    render() {
        if ((this.props.loading && !this.props.profile) || !this.props.profile) {
            return <ProgressOverlay loading={ true }/>;
        }
        else {
            return (
                <React.Fragment>
                    <ProgressOverlay loading={ this.props.loading } />

                    <Personalization profile={ this.props.profile } token={ this.props.token } handleSave={ this.handleSave } />
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = ({ user, auth0 }, ownProps) => {
    return {
        loading: user.loading,
        profile: user.profile,
        auth0User: auth0.user,
        token: auth0.token,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getProfile: id => dispatch(getProfile(id)),
        patchProfile: (id, values) => dispatch(patchProfile(id, values))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalizationContainer);
