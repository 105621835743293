import {
    Button,
    Chip,
    FormControl,
    FormHelperText,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { CancelToken } from 'axios';
import { Form, Formik, getIn } from 'formik';
import { isString } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import api from '../../utils/api';
import { filter } from '../../utils/json';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [options, setOptions] = React.useState([]);
    let source;

    const findSkills = query => {
        if (source) {
            source.cancel();
        }

        source = CancelToken.source();

        api.get(`skills?name=${query}`, {
            cancelToken: source.token,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            }
        }).then(function (response) {
            setOptions(response.data);
        }).catch(() => {});
    };

    const addSkill = (tmpReferenceId, name) => {
        api.get(`skills?name=${name}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            }
        }).then(function (response) {
            if (0 < response.data.length) {
                const newOptions = options.map(option => option.id === tmpReferenceId ? response.data : option);
                setOptions(newOptions);
            }
            else {
                api.post(`skills`, {
                    name,
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${props.token}`,
                    }
                }).then(function (response) {
                    const newOptions = options.map(option => option.id === tmpReferenceId ? response.data : option);
                    setOptions(newOptions);
                }).catch(function () {});
            }
        }).catch(() => {});
    };

    return (
        <Formik
            initialValues={ filter(props.profile, ['user_metadata.skills']) }
            onSubmit={ (values) => props.handleSave(values) }
            validationSchema={ Yup.object().shape({
                user_metadata: Yup.object().shape({
                    skills: Yup.array()
                        .required(t('validation.required', { field: t('field.skills') })),
                })
            }) }
        >
            { ({ values, touched, errors, handleSubmit, setFieldValue }) => (
                <Form onSubmit={ handleSubmit }>
                    <FormControl fullWidth className={ classes.margin }>
                        <Autocomplete
                            id="user_metadata.skills"
                            name="user_metadata.skills"
                            autoHighlight
                            freeSolo
                            multiple
                            options={ options }
                            value={ getIn(values, 'user_metadata.skills', []) }
                            getOptionLabel={ option => option.name }
                            onChange={ (event, values) => {
                                setFieldValue('user_metadata.skills', values.map(value => {
                                    if (isString(value)) {
                                        const tmpReferenceId = uuid();
                                        addSkill(tmpReferenceId, value);
                                        return { id: tmpReferenceId, name: value };
                                    }
                                    return value;
                                }));
                            } }
                            onInputChange={ (event, value) => {
                                if (3 <= value.length) {
                                    findSkills(value);
                                }
                            } }
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={ option.name ? option.name : option } { ...getTagProps({ index }) } />
                                ))
                            }
                            renderInput={ params => (
                                <TextField
                                    label={ t('field.skills') }
                                    variant="outlined"
                                    { ...params }
                                    onBlur={ () => { setOptions([]) } }
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={ !!(getIn(touched, 'user_metadata.skills') && getIn(errors, 'user_metadata.skills')) }
                                />
                            )}
                        />
                        { getIn(errors, 'user_metadata.skills') && (
                            <FormHelperText error>{ getIn(errors, 'user_metadata.skills') }</FormHelperText>
                        ) }
                    </FormControl>

                    <FormControl className={ classes.margin }>
                        <Button type="submit"  variant="contained" color="primary" disableElevation>
                            { t('action.save') }
                        </Button>
                    </FormControl>
                </Form>
            ) }
        </Formik>
    );
}
