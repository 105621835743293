export const AUTH0_SET_TOKEN = '@@auth0/SET_TOKEN';
export const AUTH0_SET_USER = '@@auth0/SET_USER';

export const setToken = token => ({
    type: AUTH0_SET_TOKEN,
    token,
});

export const setUser = user => ({
    type: AUTH0_SET_USER,
    user,
});
