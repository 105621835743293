import produce from 'immer';
import { fromJS } from 'immutable';
import {
    COUNTRY_GET_COLLECTION_REQUEST,
    COUNTRY_GET_COLLECTION_SUCCESS,
    COUNTRY_GET_COLLECTION_FAILURE,
} from '../actions/country';

const reducer = (state = { loading: false, countries: null }, action) => produce(state, (draft) => {
    switch (action.type) {
        case COUNTRY_GET_COLLECTION_REQUEST:
            draft.loading = true;
            break;

        case COUNTRY_GET_COLLECTION_SUCCESS:
            const countries = fromJS(action.payload.countries).map((value, key) => ({ code: key, label: value })).toIndexedSeq();
            draft.countries = countries.sortBy(country => country.label);
            draft.loading = false;
            break;

        case COUNTRY_GET_COLLECTION_FAILURE:
            draft.loading = false;
            break;

        default:
            break;
    }
});

export default reducer;
