import React from 'react';
import { connect } from 'react-redux'
import {
    withStyles,
} from '@material-ui/core';
import { getCountries } from '../redux/actions/country';
import { createPasswordChangeTicket, getProfile, patchProfile } from '../redux/actions/user';
import PersonalInformation from '../components/PersonalInformation';
import ProgressOverlay from '../components/ProgressOverlay';
import api from '../utils/api';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class PersonalInfoContainer extends React.Component {
    componentDidMount() {
        if (null === this.props.countries) {
            this.props.getCountries();
        }

        if (null === this.props.profile) {
            this.props.getProfile(this.props.auth0User.sub);
        }
    }

    handleSave = (values) => {
        const allowed = ['given_name', 'family_name', 'user_metadata'];

        const filtered = Object.keys(values)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});

        this.props.patchProfile(this.props.auth0User.sub, filtered);
    };

    handlePasswordChangeRequest = () => {
        this.props.createPasswordChangeTicket(this.props.auth0User.email);
    };

    handlePictureSave = (e) => {
        const self = this;
        const data = new FormData();
        data.append('file', e.currentTarget.files[0]);

        api.post(`pictures`, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.auth0Token}`,
            }
        }).then(function (response) {
            self.props.patchProfile(self.props.auth0User.sub, {
                picture: response.data.content_url
            });
        }).catch(function () {});
    };

    render() {
        if ((this.props.loading && !this.props.profile) || !this.props.profile || !this.props.countries) {
            return <ProgressOverlay loading={ true }/>;
        }
        else {
            return (
                <React.Fragment>
                    <ProgressOverlay loading={ this.props.loading }/>

                    <PersonalInformation
                        profile={ this.props.profile }
                        countries={ this.props.countries }
                        handleSave={ this.handleSave }
                        handlePasswordChangeRequest={ this.handlePasswordChangeRequest }
                        handlePictureSave={ this.handlePictureSave }
                    />
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = ({ user, auth0, country }, ownProps) => {
    return {
        loading: user.loading || country.loading,
        profile: user.profile,
        countries: country.countries,
        auth0User: auth0.user,
        auth0Token: auth0.token,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCountries: () => dispatch(getCountries()),
        getProfile: id => dispatch(getProfile(id)),
        patchProfile: (id, values) => dispatch(patchProfile(id, values)),
        createPasswordChangeTicket: email => dispatch(createPasswordChangeTicket(email))
    }
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalInfoContainer));
