import produce from 'immer';
import {
    AUTH0_SET_TOKEN,
    AUTH0_SET_USER,
} from '../actions/auth0';

const reducer = (state = { token: null, user: null }, action) => produce(state, (draft) => {
    switch (action.type) {
        case AUTH0_SET_TOKEN:
            draft.token = action.token;
            break;

        case AUTH0_SET_USER:
            draft.user = action.user;
            break;

        default:
            break;
    }
});

export default reducer;
