import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import React from 'react';

export default (props) => {
    return (
        <FormControl variant="outlined">
            <InputLabel id={ props.property + '-label' }>{ props.label }</InputLabel>
            <Select
                id={ props.property }
                labelId={ props.property + '-label' }
                label={ props.label }
                name={ props.property }
                value={ props.value }
                onChange={ props.onChange }
                onBlur={ props.onBlur }
                error={ !!props.error }
            >
                { props.options && props.options.map(option => (<MenuItem key={ option.value } value={ option.value }>{ option.label }</MenuItem>)) }
            </Select>
            { props.error && (
                <FormHelperText error>{ props.error }</FormHelperText>
            ) }
        </FormControl>
    );
}