import { applyMiddleware, createStore, compose } from 'redux';
import { createMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { fetch } from 'whatwg-fetch';
import rootReducer from './rootReducer';
import tokenMiddleware from './middleware/tokenMiddleware';

/**
 * Store creator
 */
export default () => {
    // SECURITY WARNING
    //
    // The environment testing condition MUST BE within this scope and cannot be passed
    // as an argument. Otherwise Uglify can't reduce
    // DevTools Extension from the production code and will lead data-leak.

    const apiMiddleware = createMiddleware({ fetch });
    const apply = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;
    const store = createStore(
        rootReducer,
        apply(applyMiddleware(tokenMiddleware, apiMiddleware))
    );

    return store;
};
