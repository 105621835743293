import { isRSAA, RSAA } from 'redux-api-middleware';

const tokenMiddleware = store => next => action => {
    if (isRSAA(action)) {
        action[RSAA].headers = {
            ...action[RSAA].headers,
            'Authorization': `Bearer ${store.getState().auth0.token}`,
        };
    }
    return next(action);
};

export default tokenMiddleware;
