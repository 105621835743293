import produce from 'immer';
import {
    USER_GET_ITEM_REQUEST,
    USER_GET_ITEM_SUCCESS,
    USER_GET_ITEM_FAILURE,
    USER_PATCH_ITEM_REQUEST,
    USER_PATCH_ITEM_SUCCESS,
    USER_PATCH_ITEM_FAILURE,
} from '../actions/user';

const reducer = (state = { loading: false, profile: null }, action) => produce(state, (draft) => {
    switch (action.type) {
        case USER_GET_ITEM_REQUEST:
            draft.loading = true;
            break;

        case USER_GET_ITEM_SUCCESS:
            draft.profile = action.payload;
            draft.loading = false;
            break;

        case USER_GET_ITEM_FAILURE:
            draft.loading = false;
            break;

        case USER_PATCH_ITEM_REQUEST:
            draft.loading = true;
            break;

        case USER_PATCH_ITEM_SUCCESS:
            draft.profile = action.payload;
            draft.loading = false;
            break;

        case USER_PATCH_ITEM_FAILURE:
            draft.loading = false;
            break;

        default:
            break;
    }
});

export default reducer;
