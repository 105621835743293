import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux'
import createAuth0Client from '@auth0/auth0-spa-js';
import { setToken, setUser } from './redux/actions/auth0';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setToken: token => dispatch(setToken(token)),
        setUser: user => dispatch(setUser(user)),
    }
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = connect(mapStateToProps, mapDispatchToProps)(({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...props
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(props);
            setAuth0(auth0FromHook);

            if (window.location.search.includes("code=")) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback(appState);
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated();

            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                setUser(user);

                props.setToken(await auth0FromHook.getTokenSilently());
                props.setUser(user);
            }

            setLoading(false);
        };
        initAuth0();
        // eslint-disable-next-line
    }, []);

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };

    return (
        <Auth0Context.Provider value={{
            isAuthenticated,
            user,
            loading,
            handleRedirectCallback,
            getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
            loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
            getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
            getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
            logout: (...p) => auth0Client.logout(...p)
        }}>
            {children}
        </Auth0Context.Provider>
    );
});
