import { Button, FormControl, FormHelperText, TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, getIn } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TextField from '../TextField';
import { filter } from '../../utils/json';
import {Autocomplete} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={ filter(props.profile, [
                'user_metadata.address.street_address',
                'user_metadata.address.postal_code',
                'user_metadata.address.locality',
                'user_metadata.address.country'
            ]) }
            onSubmit={ (values) => props.handleSave(values) }
            validationSchema={ Yup.object().shape({
                'user_metadata': Yup.object().shape({
                    'address': Yup.object().shape({
                        'street_address': Yup.string()
                            .required(t('validation.required', { field: t('field.street_address') })),
                        'postal_code': Yup.string()
                            .required(t('validation.required', { field: t('field.postal_code') })),
                        'locality': Yup.string()
                            .required(t('validation.required', { field: t('field.locality') })),
                        'country': Yup.string()
                            .nullable()
                            .required(t('validation.required', { field: t('field.country') })),
                    })
                })
            })}
        >
            { ({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Form onSubmit={ handleSubmit }>
                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.street_address') }
                            property="user_metadata.address.street_address"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.address.street_address', '') }
                            error={ getIn(touched, 'user_metadata.address.street_address') && getIn(errors, 'user_metadata.address.street_address') }
                        />
                    </FormControl>

                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.postal_code') }
                            property="user_metadata.address.postal_code"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.address.postal_code', '') }
                            error={ getIn(touched, 'user_metadata.address.postal_code') && getIn(errors, 'user_metadata.address.postal_code') }
                        />
                    </FormControl>

                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.locality') }
                            property="user_metadata.address.locality"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.address.locality', '') }
                            error={ getIn(touched, 'user_metadata.address.locality') && getIn(errors, 'user_metadata.address.locality') }
                        />
                    </FormControl>

                    <FormControl fullWidth className={ classes.margin }>
                        <Autocomplete
                            id="user_metadata.address.country"
                            name="user_metadata.address.country"
                            autoHighlight
                            options={ props.countries.toJS() }
                            value={ getIn(values, 'user_metadata.address.country', '') }
                            onChange={ (event, newValue) => {
                                setFieldValue('user_metadata.address.country', null !== newValue ? newValue.code : null);
                            } }
                            getOptionLabel={ option => {
                                return option && props.countries.find(country => country.code === option.code || country.code === option).label;
                            } }
                            renderOption={ option => (
                                <React.Fragment>
                                    { option.label }
                                </React.Fragment>
                            )}
                            renderInput={ params => (
                                <MuiTextField
                                    label={ t('field.country') }
                                    variant="outlined"
                                    { ...params }
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={ !!(getIn(touched, 'user_metadata.address.country') && getIn(errors, 'user_metadata.address.country')) }
                                />
                            )}
                        />
                        { getIn(errors, 'user_metadata.address.country') && (
                            <FormHelperText error>{ getIn(errors, 'user_metadata.address.country') }</FormHelperText>
                        ) }
                    </FormControl>

                    <FormControl className={ classes.margin }>
                        <Button type="submit"  variant="contained" color="primary" disableElevation>
                            { t('action.save') }
                        </Button>
                    </FormControl>
                </Form>
            ) }
        </Formik>
    );
}
