import { createAction } from 'redux-api-middleware';

export const USER_GET_ITEM_REQUEST = '@@user/USER_GET_ITEM_REQUEST';
export const USER_GET_ITEM_SUCCESS = '@@user/USER_GET_ITEM_SUCCESS';
export const USER_GET_ITEM_FAILURE = '@@user/USER_GET_ITEM_FAILURE';

export const USER_PATCH_ITEM_REQUEST = '@@user/USER_PATCH_ITEM_REQUEST';
export const USER_PATCH_ITEM_SUCCESS = '@@user/USER_PATCH_ITEM_SUCCESS';
export const USER_PATCH_ITEM_FAILURE = '@@user/USER_PATCH_ITEM_FAILURE';

export const USER_CREATE_PASSWORD_CHANGE_REQUEST = '@@user/USER_CREATE_PASSWORD_CHANGE_REQUEST';
export const USER_CREATE_PASSWORD_CHANGE_SUCCESS = '@@user/USER_CREATE_PASSWORD_CHANGE_SUCCESS';
export const USER_CREATE_PASSWORD_CHANGE_FAILURE = '@@user/USER_CREATE_PASSWORD_CHANGE_FAILURE';

export const getProfile = (id) => createAction({
    endpoint: `${process.env.REACT_APP_ACCOUNT_API_BASE_URL}/users/${id}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/ld+json'
    },
    types: [
        USER_GET_ITEM_REQUEST,
        USER_GET_ITEM_SUCCESS,
        USER_GET_ITEM_FAILURE
    ]
});

export const patchProfile = (id, values) => createAction({
    endpoint: `${process.env.REACT_APP_ACCOUNT_API_BASE_URL}/users/${id}`,
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/merge-patch+json',
        'Accept': 'application/ld+json'
    },
    body: JSON.stringify(values),
    types: [
        USER_PATCH_ITEM_REQUEST,
        USER_PATCH_ITEM_SUCCESS,
        USER_PATCH_ITEM_FAILURE
    ]
});

export const createPasswordChangeTicket = (email) => createAction({
    endpoint: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: email,
        connection: process.env.REACT_APP_AUTH0_CONNECTION
    }),
    types: [
        USER_CREATE_PASSWORD_CHANGE_REQUEST,
        USER_CREATE_PASSWORD_CHANGE_SUCCESS,
        USER_CREATE_PASSWORD_CHANGE_FAILURE
    ]
});
