import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { NavLink, Router, Route, Switch } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    CssBaseline,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TuneIcon from '@material-ui/icons/Tune';
import { useTranslation } from 'react-i18next';
import AnonymousHome from './components/AnonymousHome';
import ProgressOverlay from './components/ProgressOverlay';
import HomeContainer from './containers/HomeContainer';
import PersonalInfoContainer from './containers/PersonalInfoContainer';
import PersonalizationContainer from './containers/PersonalizationContainer';
import history from "./utils/history";
import { useAuth0 } from "./react-auth0-spa";
import logo from './logo.svg';

const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'white',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

function App() {
    const classes = useStyles();
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
    const { t } = useTranslation();

    useEffect(() => {
        if (loading || isAuthenticated === false || isAuthenticated === true) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: '/' },
                prompt: 'none'
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect]);

    if (loading || isAuthenticated === undefined) {
        return (
            <ProgressOverlay loading={ true }/>
        );
    }

    return (
        <Router history={ history }>
            <div className="App">
                <div className={ classes.root }>
                    <CssBaseline />

                    <AppBar position="fixed" className={ classes.appBar }>
                        <Toolbar>
                            <img src={logo} height="40px" alt="NLinBusiness logo" />
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        className={ classes.drawer }
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={ classes.toolbar } />

                        <List>
                            <ListItem component={ NavLink } to="/" exact>
                                <ListItemIcon><HomeIcon /></ListItemIcon>
                                <ListItemText primary={ t('home.label.menu') } />
                            </ListItem>

                            <ListItem component={ NavLink } to={ isAuthenticated ? '/personal-info' : '/' } exact disabled={ !isAuthenticated }>
                                <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                                <ListItemText primary={ t('personal_information.label.menu') } />
                            </ListItem>

                            <ListItem component={ NavLink } to={ isAuthenticated ? '/personalization' : '/' } exact disabled={ !isAuthenticated }>
                                <ListItemIcon><TuneIcon /></ListItemIcon>
                                <ListItemText primary={ t('personalization.label.menu') } />
                            </ListItem>
                        </List>
                    </Drawer>

                    <main className={ classes.content }>
                        <div className={ classes.toolbar } />

                        { isAuthenticated ? (
                            <Switch>
                                <Route path="/" component={ HomeContainer } exact />
                                <Route path="/personal-info" component={ PersonalInfoContainer } />
                                <Route path="/personalization" component={ PersonalizationContainer } />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path="/" component={ AnonymousHome } exact />
                            </Switch>
                        ) }
                    </main>
                </div>
            </div>
        </Router>
    );
}

export default hot(App);
