import { Button, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, getIn } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TextField from '../TextField';
import { filter } from '../../utils/json';

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={ filter(props.profile, ['user_metadata.phone_number']) }
            onSubmit={ (values) => props.handleSave(values) }
            validationSchema={Yup.object().shape({
                user_metadata: Yup.object().shape({
                    phone_number: Yup.string()
                        .required(t('validation.required', { field: t('field.phone_number') }))
                        .matches(/^\+[0-9]{1,15}$/, t('validation.regex', { pattern: '+31612345678' })),
                })
            })}
        >
            { ({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={ handleSubmit }>
                    <FormControl fullWidth className={ classes.margin }>
                        <TextField
                            label={ t('field.phone_number') }
                            property="user_metadata.phone_number"
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            value={ getIn(values, 'user_metadata.phone_number', '') }
                            error={ getIn(touched, 'user_metadata.phone_number') && getIn(errors, 'user_metadata.phone_number') }
                        />
                    </FormControl>

                    <FormControl className={ classes.margin }>
                        <Button type="submit"  variant="contained" color="primary" disableElevation>
                            { t('action.save') }
                        </Button>
                    </FormControl>
                </Form>
            ) }
        </Formik>
    );
}