import {
    Avatar,
    Box,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

export default (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Avatar src={ props.profile.picture } className={ classes.large }/>

                        <Typography variant="h5" gutterBottom>
                            { t('home.title.page', { given_name: props.profile.given_name, family_name: props.profile.family_name }) }
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            { t('home.text.introduction') }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
