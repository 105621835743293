import {
    FormHelperText,
    TextField,
} from '@material-ui/core';
import React from 'react';

export default (props) => {
    return (
        <React.Fragment>
            <TextField
                id={ props.property }
                label={ props.label }
                name={ props.property }
                value={ props.value }
                onChange={ props.onChange }
                onBlur={ props.onBlur }
                error={ !!props.error }
                variant="outlined"
            />
            { props.error && (
                <FormHelperText error>{ props.error }</FormHelperText>
            ) }
        </React.Fragment>
    );
}