import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './redux/store';
import { Auth0Provider } from './react-auth0-spa';
import './index.css';
import App from './App';
import ProgressOverlay from './components/ProgressOverlay';
import * as serviceWorker from './serviceWorker';

import history from './utils/history';
import './i18n';

const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const store = createStore();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <Suspense fallback={ <ProgressOverlay loading={ true }/> }>
        <Provider store={ store }>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                scope={process.env.REACT_APP_AUTH0_SCOPE}
                redirect_uri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                <App />
            </Auth0Provider>
        </Provider>
    </Suspense>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
